ul.corporation-list,
ul.order-listing {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.plus_points {
    color: #0dff2d;
}

.order_id_blue {
    color: #bbff00;
}

ul.corporation-list li {
    display: flex;
    flex-flow: row wrap;
    padding: 6px 0;
}
select.form-control.pos-select {
    color: #b2b2bc;
    padding-right: 30px;
}

.pos-devces .input-group.search {
    padding-right: 15px;
}
.employee-table-head {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
.input-group-close i {
    color: #c8d4df;
}

.input-group-close {
    position: absolute;
    right: 3px;
    top: 7px;
}
.add-user-head {
    padding-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 20px;
}
.accordion-body {
    padding: 1rem 0 !important;
}
ul.corporation-list li strong {
    width: 30%;
}
ul.user-details-list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding-left: 0;
    padding-top: 15px;
    justify-content: space-between;
}

ul.user-details-list li {
    width: 49%;
    display: flex;
    padding: 4px 0;
    flex-flow: row wrap;
    font-size: 15px;
}
.form-group select.form-control {
    margin-bottom: 14px !important;
}
ul.user-details-list.loyalty-list li strong {
    width: 40%;
    font-weight: 600;
    word-wrap: break-word;
}
ul.user-details-list li strong {
    width: 40%;
    font-weight: 600;
}
button.accordion-button {
    padding-left: 0;
    padding-right: 0;
}

p.box-cookie-tx {
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin: 0;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    border: 2px solid #7aad37 !important;
    height: 40px !important;
    width: 40px !important;
    background-position: center;
    border-radius: 12px;
}
.accordion-header strong {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #111111 !important;
}
.accordion span.dark-tx {
    margin-left: 10px;
}

span.dark-tx {
    color: #7aad37;
}

.modal-dialog {
    margin: 0 !important;
    border: none !important;
    width: 100% !important;
    max-width: 100%;
}

img.invoive-img,
.box-cookie-tx span svg {
    margin-right: 8px;
}

ul.showuser-list li {
    padding: 6px;

    font-size: 15px;
}
ul.showuser-list li:nth-child(2n + 1) {
    background: #f5f7f9;
}
h5.add {
    margin-left: 10px;
}

table {
    border-collapse: separate !important;
    border-spacing: 1px 10px !important;
}
span.vip-indicator::before {
    content: '';
    background: #ffb060;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    position: absolute;
    left: 4px;
    top: 21px;
}

table tbody tr td {
    position: relative;
}
table thead th span {
    width: max-content;
}
.MuiTablePagination-actions {
    display: flex;
}

.MuiTablePagination-actions > button {
    padding: 0;
    width: max-content;
}
label.control-label {
    font-size: 13px;
    color: rgba(17, 17, 17, 0.64);
}
.showuser-list:empty {
    border: none !important;
    box-shadow: none !important;
}
button.accordion-button {
    border-radius: 0 !important;
}

.pos-devces .input-group.search {
    padding-right: 15px;
    width: auto;
}
@media (min-width: 1900px) {
    table tbody tr td,
    table thead th {
        font-size: 16px !important;
    }
    .accordion-header strong,
    .accordion span.dark-tx,
    .accordion p.box-cookie-tx span {
        font-size: 19px !important;
    }
    .footer-btn {
        font-size: 19px;
    }
    ul.user-details-list li {
        padding: 5px 0;
        font-size: 19px;
    }
}
