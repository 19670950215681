@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #7aad37;
    /*--secondary: #ffffff;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /*font-family: 'Roboto' !important;*/
}

p,
strong,
span,
a,
li {
    /*font-family: 'Roboto' !important;*/
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.jashanDate input {
    padding: 0.375rem 0.75rem !important;
    margin: 0px !important;
    padding: 0.375rem 0.75rem;
}
input[type='tel']:read-only {
    background-color: #e9ecef;
}
.pColor {
    color: #7aad37;
}
.pBg {
    background-color: #7aad37;
}
.pBorder {
    border: 2px solid #7aad37 !important;
}
/* .footer-btn {
    border: 2px solid #7aad37 !important;
    width: max-content;
} */
/* Checkboxes csss */
.checkbox > input {
    height: 21px;
    width: 21px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 4px;
    border: 2px solid rgba(180, 255, 82, 0.24);
    transition-duration: 0.3s;
    background-color: #7aad371f;
    cursor: pointer;
}

.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}
.checkbox span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.checkbox > input:checked {
    background-color: #7aad37;
    border: 2px solid #7aad37;
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 6px;
    top: 1px;
    font-size: 13px;
}
/* Checkboxes csss  ends*/

.content-area {
    padding: 50px;
}

table thead tr {
    background: #f5f7f9;
}

table tbody tr td {
    padding: 6px !important;
}

table tbody tr,
table thead tr {
    outline: 1px solid #c8d4df !important;
    border-radius: 6px;
}

table tbody tr th,
table tbody tr td {
    border-bottom: none !important;
}
table thead tr th {
    border: none !important;
}
table tbody tr th,
table tbody tr td {
    padding: 2px 15px !important;
}
table thead tr th {
    padding: 10px !important;
    font-weight: 600 !important;
}
table .dropdown.table {
    margin: 0;
}

table {
    width: 99% !important;
    margin: 0 auto;
}
.input-group.search .input-group-append {
    position: absolute;
    left: 0;
    top: 8px;
}

.input-group.search .input-group-text {
    background: transparent;
    border: none;
    color: #c8d4df !important;
}

.modal strong {
    font-weight: 600;
}
.mbottom {
    margin-bottom: 22px !important;
}
.input-group.search input.form-control {
    padding-left: 40px;
    border-radius: 6px !important;
    line-height: 22px;
}

.input-group.search input.form-control::placeholder {
    color: rgba(17, 17, 17, 0.32) !important;
}

.MuiTableCell-head svg {
    fill: #7aad37 !important;
}

.table-header {
    padding-bottom: 15px;
}

button.add-icon-btn {
    background: #7aad37;
    /* color: #fff !important; */
    width: max-content;
    padding: 10px 30px !important;
    font-size: 15px;
}
button.add-icon-btn img {
    margin-right: 5px;
}
.cstm-logo img {
    margin: 0 auto;
    height: 80px;
}
.dropdown-toggle::after {
    color: #7aad37 !important;
}
.info-header i {
    color: #7aad37;
}
td .dropdown .btn i {
    color: #7aad37;
}

table tbody tr:hover {
    background: #f5f7f9 !important;
    border-radius: 0 6px 6px 0 !important;
}

table tbody tr {
    position: relative;
}

table tbody tr:hover::after {
    content: '';
    background: #7aad37;
    position: absolute !important;
    left: 0;
    translate: 0 !important;
    height: 100%;
    width: 2px;
    display: table;
    top: 0;
}

/* modal css */

.info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c8d4df !important;
    padding-bottom: 15px;
    padding-top: 10px;
}

.info-header .left-info {
    display: flex;
    align-items: center;
}

.info-header h4 {
    font-size: 20px;
    font-weight: 500;
}

.info-header p,
.info-header h4 {
    margin: 0;
}
span.add-delicon {
    border: 2px solid #7aad37;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    border-radius: 12px;
    margin-left: 20px;
    cursor: pointer;
}
.info-header .left-info img,
.info-header .left-info svg {
    margin-right: 10px;
}

p.box-cookie-tx {
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin: 0;
}

h5.modal-title {
    font-size: 24px;
    font-weight: 600;
}

span.vip-tx {
    background: #ffaf60;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 30px;
}

.mright {
    margin-right: 10px !important;
}
.br-none {
    border-right: none !important;
}
input[type='tel']:read-only {
    background-color: #fff !important;
    padding: 10px 16px !important;
}
span.vip-tx {
    margin-left: 10px;
}

p.cookie-flx {
    display: flex;
    justify-content: space-between;
}

ul.order-listing li {
    padding: 4px 0;
}

p.view-notes-tx {
    border: 1px solid #c8d4df !important;
    border-radius: 6px;
    padding: 14px;
    margin: 10px 0;
}

div#accordionFlushExample {
    border-top: 1px solid #c8d4df !important;
}

/* input.form-control {
    border: 1px solid #c8d4df !important;
    border-radius: 5px !important;
    background: transparent !important;
} */
/* .form-check-input:checked[type='checkbox'] {
    width: 30px !important;
} */

input.form-control,
select.form-control {
    font-size: 16px;
    color: rgba(17, 17, 17, 0.8);
    font-weight: 500;
    padding: 10px 16px;
}
.modal-dialog {
    border-radius: 12px !important;
    background: transparent !important;
}
.modal-content {
    padding: 26px 30px;
    border-radius: 10px !important;
    border: 0px solid #dfe6ec !important;
}

/* .form-switch .form-check-input {
    width: 2em !important;
    background: 'green';
} */
.modal-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
}

.modal-body {
    padding: 15px 0 !important;
}

.modal-footer {
    padding: 20px 0 0 0 !important;
}
.dropdown-toggle::after {
    display: none;
}

@media (min-width: 1900px) {
    table tbody tr td,
    table thead th {
        font-size: 16px !important;
    }
    .info-header h4 {
        font-size: 24px;
    }

    span.vip-tx {
        font-size: 16px;
    }
    p.view-notes-tx {
        font-size: 18px;
    }
}

/* #toggle-swich::after {
    content: '';
    @apply h-32 w-32 rounded-full bg-black absolute left-0 top-0 transform  scale-110 transition duration-200;
}
#toggle-swich:checked::after {
    @apply transform scale-110 translate-x-32;
} */
